<template>
  <div class="w-100">
    <div class="list-group shadow-sm rounded-lg mb-3 mb-md-0">
      <router-link to="/profile" class="list-group-item list-group-item-action">
        <b-icon-person-fill class="text-primary mr-2" /> {{$t('profile.menuProfile')}}
      </router-link>
      <router-link to="/profile/bookings" class="list-group-item list-group-item-action">
        <b-icon-calendar-check-fill class="text-primary mr-2" /> {{$t('profile.menuBookings')}}
      </router-link>
      <router-link to="/profile/groupBookings" class="list-group-item list-group-item-action">
        <b-icon-people-fill class="text-primary mr-2" /> {{$t('profile.groupBookings')}}
      </router-link>
      <router-link to="/profile/invoices" class="list-group-item list-group-item-action">
        <b-icon-file-text-fill class="text-primary mr-2" /> {{$t('profile.invoiceReport')}}
      </router-link>
      <router-link v-if="$store.state?.user?.type==='3'" to="/profile/commissionReport" class="list-group-item list-group-item-action">
        <b-icon-file-bar-graph-fill class="text-primary mr-2" /> {{$t('profile.commissionReport')}}
      </router-link>
      <a class="cursor list-group-item list-group-item-action text-danger" @click="logOut">
        <b-icon-power class="mr-2" /> {{$t('logout')}}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ActionMenu',
  methods: {
    logOut () {
      this.$store.commit('setPartnerLogout')
      this.$helper.setLogOut()
      this.$router.push('/')
    }
  }
}
</script>
