<template>
  <div class="container-fluid copyright py-3">
    <div class="row">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm order-1 order-sm-0 mt-3 mt-sm-0">
            <p class="">© {{new Date().getFullYear()}}
              <a href="https://www.snowoffice.at" target="_blank">Snowoffice GmbH</a>
              {{$t('allRightsReserved')}}
            </p>
          </div>
          <div class="col-12 col-sm-auto order-0 order-sm-1">
            <ul class="languageUl">
              <li><a @click="setLanguage('en')" class="cursor changeLanguage">EN</a></li>
              <li><a @click="setLanguage('de')" class="cursor changeLanguage">DE</a></li>
              <li><a @click="setLanguage('es')" class="cursor changeLanguage">ES</a></li>
              <li><a @click="setLanguage('hr')" class="cursor changeLanguage">HR</a></li>
              <li><a @click="setLanguage('it')" class="cursor changeLanguage">IT</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopyRights',
  methods: {
    setLanguage (language) {
      localStorage.setItem('HosEngineFrontLanguage', language)
      this.$i18n.locale = language
      this.$moment.locale(this.$i18n.locale)
    }
  }
}
</script>

<style scoped>

</style>
