import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '../views/MainPage.vue'
import ProfilePage from '../views/ProfilePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/error',
    name: 'mainError',
    meta: { title: 'Error' },
    component: () => import('../views/MainError.vue')
  },
  {
    path: '/adminFill',
    name: 'adminFill',
    meta: { title: 'Admin Fill Data' },
    component: () => import('../views/AdmillFill.vue')
  },
  {
    path: '/adminFillData',
    name: 'adminFillData',
    meta: { title: 'Admin Fill Data' },
    component: () => import('../views/AdminFillData.vue')
  },
  { path: '/saferPayProcess/:reservationId', name: 'saferPayProcess', component: () => import('../views/Payment/saferPayProcess.vue'), meta: { title: 'Processing' } },
  {
    path: '/',
    component: MainPage,
    children: [
      { path: '/', name: 'home', component: () => import('../views/Home.vue'), meta: { title: 'Home' } },
      { path: '/availability', name: 'availability', component: () => import('../views/Availability.vue'), meta: { title: 'Search' } },
      { path: '/groupBooking', name: 'groupBooking', component: () => import('../views/GroupBooking.vue'), meta: { title: 'Search' } },
      { path: '/house/:houseName', hash: 'iamHash', name: 'house', component: () => import('../views/House.vue'), meta: { title: 'Availability' } },
      { path: '/booking', name: 'booking', component: () => import('../views/Booking.vue'), meta: { title: 'Booking' } },
      { path: '/check', name: 'check', component: () => import('../views/Check.vue'), meta: { title: 'Check Booking' } },
      { path: '/viewBooking', name: 'viewBooking', component: () => import('../views/ViewBooking.vue'), meta: { title: 'View Booking' } },
      { path: '/contact', name: 'contact', component: () => import('../views/Contact.vue'), meta: { title: 'Contact' } },
      { path: '/impressum', name: 'impressum', component: () => import('../views/Impressum.vue'), meta: { title: 'Impressum' } },
      { path: '/agb', name: 'agb', component: () => import('../views/Agb.vue'), meta: { title: 'AGB' } },
      { path: '/customerLogin', name: 'customerLogin', component: () => import('../views/CustomerLogin.vue'), meta: { title: 'Customer Login' } },
      { path: '/paymentProcess/:provider/:reservationId', name: 'paymentProcess', component: () => import('../views/Payment/Process.vue'), meta: { title: 'Processing Payment' } },
      { path: '/paymentSuccess/:reservationId', name: 'paymentSuccess', component: () => import('../views/Payment/Success.vue'), meta: { title: 'Processing Success' } },
      { path: '/paymentError/:reservationId', name: 'paymentError', component: () => import('../views/Payment/Error.vue'), meta: { title: 'Processing Error' } },
      {
        path: '/profile',
        component: ProfilePage,
        meta: { title: 'Profile', auth: true },
        children: [
          { path: '/profile', name: 'profile', component: () => import('../views/Profile/Home.vue'), meta: { title: 'Profile' } },
          { path: '/profile/bookings', name: 'profileBookings', component: () => import('../views/Profile/Bookings.vue'), meta: { title: 'Bookings' } },
          { path: '/profile/bookingDetails/:id', name: 'bookingDetails', component: () => import('../views/Profile/BookingDetails.vue'), meta: { title: 'Booking Details' } },
          { path: '/profile/groupBookings', name: 'groupBookings', component: () => import('../views/Profile/GroupBookings.vue'), meta: { title: 'Group Bookings' } },
          { path: '/profile/groupBookingDetails/:id', name: 'groupBookingDetails', component: () => import('../views/Profile/GroupBookingDetails.vue'), meta: { title: 'Group Booking Details' } },
          { path: '/profile/invoices', name: 'invoices', component: () => import('../views/Profile/Invoices.vue'), meta: { title: 'Invoices' } },
          { path: '/profile/commissionReport', name: 'commissionReport', component: () => import('../views/Profile/CommissionReport.vue'), meta: { title: 'Commission Reports' } }
        ]
      },
      {
        path: '/*',
        name: 'pageNotFound',
        meta: { title: 'Page Not Found' },
        component: () => import('../views/PageNotFound.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.name === 'house') {
      return false
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0,
        behavior: 'smooth'
      }
    }
  }
})
const DEFAULT_TITLE = 'HOS-Booking'
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})
export default router
