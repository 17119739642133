<template>
  <div class="cookiePopUp">
    <div class="container">
      <div class="col-12">
        <div class="row my-0">
          <div class="col-12 col-sm">
            Cookies helfen uns, unsere Dienstleistungen zu erbringen. <br>
            Durch die Nutzung unserer Dienste stimmen Sie der Verwendung von Cookies zu.
            <a target="_blank" class="text-white text-underline" href="<?php echo base_url('impressum') ?>">Informationen</a>
          </div>
          <div class="col-auto text-center mt-3 mt-sm-0">
            <a class="btn btn-blue" @click="acceptCookie">
              Ich stimme zu
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CookiePopUp',
  methods: {
    acceptCookie () {
      this.$emit('acceptCookie')
    }
  }
}
</script>

<style scoped>

</style>
