<template>
  <div class="w-100">
    <page-loader v-if="state==='loading'" />
    <div v-else-if="state==='success'" class="w-100">
      <div class="container-fluid bg-light py-4 py-md-5">
        <div class="row">
          <div class="container">
            <div class="row pb-4">
              <div class="col-12 col-md-3">
                <action-menu />
              </div>
              <div class="col-12 col-md-9">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ActionMenu from '../components/Profile/ActionMenu.vue'
import PageLoader from '../components/Common/PageLoader.vue'
export default {
  name: 'ProfilePage',
  components: {
    PageLoader,
    ActionMenu
  },
  data () {
    return {
      state: 'loading'
    }
  },
  mounted () {
    this.validateToken()
  },
  methods: {
    validateToken () {
      this.state = 'loading'
      this.$http.post('login/validateCustomerToken').then(response => {
        if (response.data.error === undefined) {
          this.logOut()
        } else if (response.data.error) {
          this.logOut()
        } else {
          this.$store.commit('setLoggedUser', response.data.user)
          this.$helper.setLogin(response.data)
          this.state = 'success'
        }
      }).catch(() => {
        this.logOut()
      })
    },
    logOut () {
      this.$router.push('/')
    }
  }
}
</script>
