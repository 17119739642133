<template>
  <div class="container-fluid py-5">
    <div class="container">
      <div class="col-12 py-5">
        <div class="animationWrapper">
          <LottieAnimation :animation-data="pageLoader" :auto-play="true" :loop="true" :speed="2" ref="anim" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageLoader from "@/assets/loader/page.json"

import LottieAnimation from 'lottie-web-vue'
export default {
  name: 'PageLoader',
  components: {LottieAnimation},
  data () {
    return {
      pageLoader: pageLoader
    }
  }
}
</script>

<style scoped lang="scss">
.animationWrapper {
  width: 90%;
  max-width: 420px;
  margin: auto;
}
</style>
