import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import i18n from '../i18n'
// const euFormatter = new Intl.NumberFormat(
//   'de-DE', { style: 'currency', currency: 'EUR' })
const eu = new Intl.NumberFormat('de-DE',
  { style: 'decimal', minimumFractionDigits: 2 })

const helper = {
  getCurrencyLanguage () {
    return i18n.locale
  },
  euFormatter () {
    return new Intl.NumberFormat(this.getCurrencyLanguage(), { style: 'currency', currency: this.getCurrency() })
  },
  getCurrency () {
    return store.state.currency
  },
  convertToCurrency (number, decimalPoint = 2) {
    const currency = this.getCurrency()
    const formatter = new Intl.NumberFormat(this.getCurrencyLanguage(), { style: 'currency', minimumFractionDigits: decimalPoint, currency: currency })
    return formatter.format(number)
  },
  getAccountCountry () {
    return store?.state?.settings?.accountCountry
  },
  getAccountConfig () {
    return store?.state?.settings?.config
  },
  // showGuestFullDetails () {
  //   const country = this.getAccountCountry()
  //   return (country === 'IT' || country === 'CH' || country === 'HR')
  // },
  usToEuSplitter (input) {
    return input.replace(/[,.]/g, function (x) { return x === ',' ? '.' : ',' })
  },
  euToUs (input) {
    return input.replace(/[,.]/g, function (x) { return x === ',' ? '.' : '' })
  },
  convertToEu (number) {
    return eu.format(number)
  },
  generateHouseLink (room, params, roomId) {
    let link = '/house/' + room.slug
    link += '?id=' + room.houseCode
    link += '&checkIn=' + params.checkIn
    link += '&checkOut=' + params.checkOut
    link += '&rooms=' + params.rooms
    link += '&adults=' + params.adults
    link += '&children=' + params.children
    if (roomId !== '0') {
      link += '&selectedRoom=' + roomId
    }
    if (params.groupId !== undefined) {
      link += '&groupId=' + params.groupId
    }
    return link
  },
  getArticlePricing (article, totalNights, totalRooms, adults, children) {
    const thisAdults = (article.applyAdult === 1) ? adults : 0
    const thisChildren = (article.applyChild === 1) ? children : 0
    const totalPerson = thisAdults + thisChildren
    const articleType = article.articleType
    const rate = article.articleRate
    let qty = 0
    if (articleType === '1') {
      qty = 1
    } else if (articleType === '2') {
      qty = totalNights * totalPerson
    } else if (articleType === '3') {
      qty = totalPerson
    } else if (articleType === '4') {
      qty = totalRooms
    } else if (articleType === '5') {
      qty = totalNights
    } else {
      qty = totalRooms * totalNights
    }
    const finalPricing = qty * rate
    return {
      price: finalPricing,
      qty: qty
    }
  },
  setLogin (data) {
    localStorage.setItem('HosCustomerAuthToken', data.token)
    localStorage.setItem('HosCustomerData', JSON.stringify(data.user))
  },
  setLogOut () {
    localStorage.setItem('HosCustomerAuthToken', '')
    localStorage.setItem('HosCustomerData', '')
  },
  printUrl (url, params, type = 'application/pdf') {
    this.showLoader()
    axios.get(url, {
      responseType: (type === 'application/pdf') ? 'arraybuffer' : 'blob',
      params: params
    }).then(response => {
      this.hideLoader()
      let blob
      if (type === 'application/pdf') {
        blob = new Blob([response.data], { type: type })
      } else if (type === 'text/csv') {
        blob = new Blob([response.data], { type: 'text/csv' })
      } else {
        blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
      }
      const url = window.URL.createObjectURL(blob)
      const newWin = window.open(url, 'hosbooking')
      if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
        Vue.swal({
          icon: 'error',
          title: i18n.t('popupBlocked'),
          text: i18n.t('unBlockPopupInfo'),
          showConfirmButton: true,
          iconColor: 'red',
          background: '#fce4ec'
        })
      }
    }).catch((error) => {
      this.showError(error.toString())
    })
  },
  downloadZip (url, params, fileName = 'HosBooking.zip') {
    this.showLoader()
    axios.get(url, {
      responseType: 'blob',
      params: params
    }).then(response => {
      this.hideLoader()
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName) // change the file name if needed
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }).catch((error) => {
      this.showError(error.toString())
    })
  },
  showError (message) {
    try {
      this.errorSound.play()
    } catch (e) {}
    this.hideLoader()
    Vue.swal({
      icon: 'error',
      title: message,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      heightAuto: false,
      timer: 3000,
      iconColor: 'red',
      background: '#fce4ec',
      customClass: {
        container: 'swal-width'
      },
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Vue.swal.stopTimer)
        toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
      }
    })
  },
  showLoader (message = '') {
    const text = (message === '' || message === undefined) ? 'Processing' : message
    Vue.swal.fire({
      title: '',
      text: text,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      heightAuto: false,
      width: '16rem',
      willOpen: () => {
        Vue.swal.showLoading()
      },
      showClass: {
        popup: 'swalBlurBody'
      }
    })
  },
  hideLoader () {
    Vue.swal.close()
  },
  showErrorInfo (message) {
    this.hideLoader()
    Vue.swal({
      icon: 'warning',
      title: i18n.t('error'),
      text: message,
      showConfirmButton: false,
      keydownListenerCapture: false,
      heightAuto: false,
      showCancelButton: true,
      cancelButtonText: i18n.t('understand'),
      customClass: {
        popup: 'error-swal'
      }
    })
  },
  idCardTypes () {
    return [
      { value: '', text: i18n.t('select') },
      { value: 'PASS', text: i18n.t('passport') },
      { value: 'ID', text: 'ID' },
      { value: 'AUTO', text: i18n.t('drivingLicense') }
    ]
  },
  getItalyProvince (stateCode, country) {
    const outPut = [{ value: '', text: 'Select Province' }]
    if (stateCode && country && country.toLowerCase() === 'it') {
      const state = stateCode.toString().toLowerCase()
      if (state === 'abruzzo') {
        outPut.push({ value: 'CH', text: 'CH - Chieti' })
        outPut.push({ value: 'AQ', text: 'AQ - L\'Aquila' })
        outPut.push({ value: 'PE', text: 'PE - Pescara' })
        outPut.push({ value: 'TE', text: 'TE - Teramo' })
      } else if (state === 'basilicata') {
        outPut.push({ value: 'MT', text: 'MT - Matera' })
        outPut.push({ value: 'PZ', text: 'PZ - Potenza' })
      } else if (state === 'calabria') {
        outPut.push({ value: 'CS', text: 'CS - Cosenza' })
        outPut.push({ value: 'CZ', text: 'CZ - Catanzaro' })
        outPut.push({ value: 'KR', text: 'KR - Crotone' })
        outPut.push({ value: 'RC', text: 'RC - Reggio Calabria' })
        outPut.push({ value: 'VV', text: 'VV - Vibo Valentia' })
      } else if (state === 'campania') {
        outPut.push({ value: 'AV', text: 'AV - Avellino' })
        outPut.push({ value: 'BN', text: 'BN - Benevento' })
        outPut.push({ value: 'CE', text: 'CE - Caserta' })
        outPut.push({ value: 'NA', text: 'NA - Napoli' })
        outPut.push({ value: 'SA', text: 'SA - Salerno' })
      } else if (state === 'emilia-romagna') {
        outPut.push({ value: 'BO', text: 'BO - Bologna' })
        outPut.push({ value: 'FE', text: 'FE - Ferrara' })
        outPut.push({ value: 'FO', text: 'FO - Forli' })
        outPut.push({ value: 'MO', text: 'MO - Modena' })
        outPut.push({ value: 'PC', text: 'PC - Piacenza' })
        outPut.push({ value: 'PR', text: 'PR - Parma' })
        outPut.push({ value: 'RA', text: 'RA - Ravenna' })
        outPut.push({ value: 'RE', text: 'RE - Reggio Emilia' })
        outPut.push({ value: 'RN', text: 'RN - Rimini' })
      } else if (state === 'friuli-venezia giulia') {
        outPut.push({ value: 'GO', text: 'GO - Gorizia' })
        outPut.push({ value: 'PN', text: 'PN - Pordenone' })
        outPut.push({ value: 'TS', text: 'TS - Trieste' })
        outPut.push({ value: 'UD', text: 'UD - Udine' })
      } else if (state === 'lazio') {
        outPut.push({ value: 'FR', text: 'FR - Frosinone' })
        outPut.push({ value: 'LT', text: 'LT - Latina' })
        outPut.push({ value: 'RI', text: 'RI - Rieti' })
        outPut.push({ value: 'RM', text: 'RM - Roma' })
        outPut.push({ value: 'VT', text: 'VT - Viterbo' })
      } else if (state === 'liguria') {
        outPut.push({ value: 'GE', text: 'GE - Genova' })
        outPut.push({ value: 'IM', text: 'IM - Imperia' })
        outPut.push({ value: 'SP', text: 'SP - La Spezia' })
        outPut.push({ value: 'SV', text: 'SV - Savona' })
      } else if (state === 'lombardia') {
        outPut.push({ value: 'BG', text: 'BG - Bergamo' })
        outPut.push({ value: 'BS', text: 'BS - Brescia' })
        outPut.push({ value: 'CO', text: 'CO - Como' })
        outPut.push({ value: 'CR', text: 'CR - Cremona' })
        outPut.push({ value: 'LC', text: 'LC - Lecco' })
        outPut.push({ value: 'LO', text: 'LO - Lodi' })
        outPut.push({ value: 'MN', text: 'MN - Mantova' })
        outPut.push({ value: 'MI', text: 'MI - Milano' })
        outPut.push({ value: 'PV', text: 'PV - Pavia' })
        outPut.push({ value: 'SO', text: 'SO - Sondrio' })
        outPut.push({ value: 'VA', text: 'VA - Varese' })
      } else if (state === 'marche') {
        outPut.push({ value: 'AN', text: 'AN - Ancona' })
        outPut.push({ value: 'AP', text: 'AP - Ascoli Piceno' })
        outPut.push({ value: 'MC', text: 'MC - Macerata' })
        outPut.push({ value: 'PS', text: 'PS - Pesaro' })
      } else if (state === 'molise') {
        outPut.push({ value: 'CB', text: 'CB - Campobasso' })
        outPut.push({ value: 'IS', text: 'IS - Isernia' })
      } else if (state === 'piemonte') {
        outPut.push({ value: 'AL', text: 'AL - Alessandria' })
        outPut.push({ value: 'AT', text: 'AT - Asti' })
        outPut.push({ value: 'BI', text: 'BI - Biella' })
        outPut.push({ value: 'CN', text: 'CN - Cuneo' })
        outPut.push({ value: 'NO', text: 'NO - Novara' })
        outPut.push({ value: 'TO', text: 'TO - Torino' })
        outPut.push({ value: 'VB', text: 'VB - Verbania' })
        outPut.push({ value: 'VC', text: 'VC - Vercelli' })
      } else if (state === 'puglia') {
        outPut.push({ value: 'BA', text: 'BA - Bari' })
        outPut.push({ value: 'BR', text: 'BR - Brindisi' })
        outPut.push({ value: 'FG', text: 'FG - Foggia' })
        outPut.push({ value: 'LE', text: 'LE - Lecce' })
        outPut.push({ value: 'TA', text: 'TA - Taranto' })
      } else if (state === 'sardegna') {
        outPut.push({ value: 'CA', text: 'CA - Cagliari' })
        outPut.push({ value: 'NU', text: 'NU - Nuoro' })
        outPut.push({ value: 'OR', text: 'OR - Oristano' })
        outPut.push({ value: 'SS', text: 'SS - Sassari' })
      } else if (state === 'sicilia') {
        outPut.push({ value: 'AG', text: 'AG - Agrigento' })
        outPut.push({ value: 'CL', text: 'CL - Caltanissetta' })
        outPut.push({ value: 'CT', text: 'CT - Catania' })
        outPut.push({ value: 'EN', text: 'EN - Enna' })
        outPut.push({ value: 'ME', text: 'ME - Messina' })
        outPut.push({ value: 'PA', text: 'PA - Palermo' })
        outPut.push({ value: 'RG', text: 'RG - Ragusa' })
        outPut.push({ value: 'SR', text: 'SR - Siracusa' })
        outPut.push({ value: 'TP', text: 'TP - Trapani' })
      } else if (state === 'toscana') {
        outPut.push({ value: 'AR', text: 'AR - Arezzo' })
        outPut.push({ value: 'FI', text: 'FI - Firenze' })
        outPut.push({ value: 'LI', text: 'LI - Livorno' })
        outPut.push({ value: 'LU', text: 'LU - Lucca' })
        outPut.push({ value: 'MS', text: 'MS - Massa' })
        outPut.push({ value: 'PI', text: 'PI - Pisa' })
        outPut.push({ value: 'PT', text: 'PT - Pistoia' })
        outPut.push({ value: 'PO', text: 'PO - Prato' })
        outPut.push({ value: 'SI', text: 'SI - Siena' })
      } else if (state === 'trentino-alto adige') {
        outPut.push({ value: 'BZ', text: 'BZ - Bolzano' })
        outPut.push({ value: 'TN', text: 'TN - Trento' })
      } else if (state === 'umbria') {
        outPut.push({ value: 'PG', text: 'PG - Perugia' })
        outPut.push({ value: 'TR', text: 'TR - Termi' })
      } else if (state === 'valle d\'aosta') {
        outPut.push({ value: 'AO', text: 'AO - Aosta' })
      } else if (state === 'veneto') {
        outPut.push({ value: 'BL', text: 'BL - Belluno' })
        outPut.push({ value: 'PD', text: 'PD - Padova' })
        outPut.push({ value: 'RO', text: 'RO - Rovigo' })
        outPut.push({ value: 'TV', text: 'TV - Treviso' })
        outPut.push({ value: 'VE', text: 'VE - Venezia' })
        outPut.push({ value: 'VI', text: 'VI - Vicenza' })
        outPut.push({ value: 'VR', text: 'VR - Verona' })
      }
    }
    return outPut
  },
  getCountriesDialCodes () {
    const getCountriesDialCodes = require("./countries");
    return getCountriesDialCodes();
  }
}
export default helper
