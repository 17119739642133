<template>
  <div>
    <top-header />
    <main-header />
    <router-view />
    <app-footer />
    <copy-rights />
    <cookie-pop-up v-if="!cookieAccepted" @acceptCookie="acceptCookie" />
  </div>
</template>

<script>
import TopHeader from '@/components/Template/TopHeader'
import MainHeader from '@/components/Template/MainHeader'
import CookiePopUp from '@/components/Template/CookiePopUp'
import CopyRights from '@/components/Template/CopyRights'
import AppFooter from '@/components/Template/AppFooter'
export default {
  name: 'MainPage',
  components: { AppFooter, CopyRights, CookiePopUp, MainHeader, TopHeader },
  created () {
    this.checkCookie()
  },
  data () {
    return {
      cookieAccepted: true
    }
  },
  methods: {
    acceptCookie () {
      const date = this.$moment().add(30, 'days')
      document.cookie = 'HosEngineCookieAccepted=1; expires=' + date
      this.cookieAccepted = true
    },
    checkCookie () {
      if (this.getCookie('HosEngineCookieAccepted') === null) {
        this.cookieAccepted = false
      } else {
        this.cookieAccepted = true
      }
    },
    getCookie (name) {
      var dc = document.cookie
      var prefix = name + '='
      var begin = dc.indexOf('; ' + prefix)
      if (begin === -1) {
        begin = dc.indexOf(prefix)
        if (begin !== 0) return null
      } else {
        begin += 2
        var end = document.cookie.indexOf(';', begin)
        if (end === -1) {
          end = dc.length
        }
      }
      return decodeURI(dc.substring(begin + prefix.length, end))
    }
  }
}
</script>

<style scoped>

</style>
