<template>
  <div>
    <div class="container-fluid topHeader" v-if="$store.state.settings!=null">
      <div class="row">
        <div class="container">
          <div class="row">
            <div class="col">
              <ul class="topMenu d-none d-lg-inline">
                <li>
                  <a :href="'tel:'+$store.state.settings.contactNumber">
                    <i class="fa fa-phone"></i>
                    {{$store.state.settings.contactNumber}}
                  </a>
                </li>
                <li>
                  <a :href="'mailto:'+$store.state.settings.reservationMail" >
                    <i class="fa fa-envelope"></i>
                    {{$store.state.settings.reservationMail}}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-auto py-2 my-sm-auto">
              <b-dropdown :text="$i18n.locale.toUpperCase()" size="sm" variant="light" right>
                <b-dropdown-item @click="setLanguage('en')" class="changeLanguage">
                  <img src="../../assets/flag/english.png" /> English
                </b-dropdown-item>
                <b-dropdown-item @click="setLanguage('de')" class="changeLanguage">
                  <img src="../../assets/flag/deutsch.png" /> Deutsch
                </b-dropdown-item>
                <b-dropdown-item @click="setLanguage('es')" class="changeLanguage">
                  <img src="../../assets/flag/espanol.png" /> Español
                </b-dropdown-item>
                <b-dropdown-item @click="setLanguage('hr')" class="changeLanguage">
                  <img src="../../assets/flag/hrvatski.png" /> Hrvatski
                </b-dropdown-item>
                <b-dropdown-item @click="setLanguage('it')" class="changeLanguage">
                  <img src="../../assets/flag/italy.png" /> Italiano
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopHeader',
  methods: {
    setLanguage (language) {
      localStorage.setItem('HosEngineFrontLanguage', language)
      this.$i18n.locale = language
      this.$moment.locale(this.$i18n.locale)
    }
  }
}
</script>

<style lang="scss">
  .changeLanguage {
    .dropdown-item {
      padding: 0.3rem 0.7rem !important;
      &:hover {
        color: #212529 !important;
      }
      img {
        width: 18px;
      }
    }
  }
</style>
