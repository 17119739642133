<template>
  <div id="app" class="w-100 clearfix">
    <div class="bodyLoader" v-if="loading">
      <div class="col-12 d-flex h-100">
        <div class="w-100 justify-content-center align-self-center">
          <img src="./assets/images/logo.png" style="width: 200px;" />
          <div class="w-100 py-3"></div>
          <b-spinner variant="success"></b-spinner>
          &nbsp;&nbsp;
          <b-spinner variant="danger"></b-spinner>&nbsp;&nbsp;
          <b-spinner variant="info"></b-spinner>&nbsp;&nbsp;
          <b-spinner variant="warning"></b-spinner>
        </div>
      </div>
    </div>
    <div v-else class="w-100 clearfix">
      <router-view/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'App',
  created () {
    this.loadDomain()
  },
  methods: {
    loadDomain () {
      this.$http.post('checkDomain').then(response => {
        if (response.data.error === null || response.data.error) {
          this.redirectToPageNotFound()
        } else {
          const currentPath = this.$route.path
          if (currentPath === '/error') {
            this.$router.push('/')
          }
          this.$store.commit('setSettings', response.data)
          this.loading = false
        }
      }).catch(() => {
        this.redirectToPageNotFound()
      })
    },
    redirectToPageNotFound () {
      const currentPath = this.$route.path
      if (currentPath !== '/error') {
        this.$router.push('/error')
      }
      this.loading = false
    }
  },
  data () {
    return {
      loading: true
    }
  },
  watch: {
    $route: function (to,from) {
      if (to.name !== 'paymentProcess') {
        localStorage.removeItem('IS_PAYMENT_PROCESSING')
      }
    }
  }
}
</script>
<style lang="scss">
.bodyLoader {
  text-align: center; z-index: 10;
  position: fixed;
  height: 100%; width: 100%;
  left: 0; top: 0;
  background: #1A3581;
}
</style>
